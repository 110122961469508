import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link, graphql } from 'gatsby';
import Sidebar from 'components/sidebar';
import ToolboxLayout from 'components/toolbox-layout';
import ToolboxGrid from 'components/toolbox-grid';
import Image from 'components/image';
import SEO from 'components/seo';
export const query = graphql`
  query Query {
    allAirtable(filter: { data: { Status: { eq: "Active" } } }) {
      edges {
        node {
          data {
            Name
            Description
            Tags
            Emoji
            Cover_Image {
              filename
              thumbnails {
                small {
                  url
                  width
                  height
                }
                large {
                  url
                  width
                  height
                }
              }
            }
            Media_Type
            Links
            Hide_Intro
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = ToolboxLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Big Ideas`}</h1>
    <ToolboxGrid data={props.data.allAirtable.edges} mdxType="ToolboxGrid" />



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      